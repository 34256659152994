


























































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
  VehicleScheduleModule as scheduleStore,
} from "@/store/modules";
import { District, Palika } from "@/store/models/meta";

@Component({
  components: {
    Input,
    Button,
    Form,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  municipality: Palika[] = [];
  districts: District[] = [];
  data() {
    return {
      options: [
        {
          value: "Day",
          label: "Day",
        },
        {
          value: "Night",
          label: "Night",
        },
      ],
      value: "",
    };
  }
  async created() {
    let vm = this;
    await metaStore.loadSourceDestinationDD();

    const id = vm.$route.query["id"];

    if (id) {
      vm.isCreate = false;
      await scheduleStore.getScheduleById(id);
    } else {
      scheduleStore.resetField();
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get schedule() {
    return scheduleStore.vehicleSchedule;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();

    await scheduleStore.createSchedule();
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.VehicleScheduleList);
  }

  async cancelForm() {
    this.$router.push(AdminRouter.VehicleScheduleList);
  }

  get SourceDestionation() {
    let a = metaStore.sourceDestinationList;

    return metaStore.sourceDestinationList;
  }

  mounted() {}
  updated() {}
}
